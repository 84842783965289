<template>
	<div class="outer-wrapper filters"> 

		<div id="top-nav"> 
			<router-link :to="{ name: 'Flights Filters' }">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>
	
		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->

			<div class="heading-section">
				<img class="icon" width="19px" src="@/assets/filters/stops.png" />
				<h1 class="heading6">Airlines</h1>
			</div>
			<div class="options">

				<div class="option select-all">
					<div class="main-text">
						<div class="title">Select all</div>
					</div>
					<Toggle v-model="selectAllAirlines" class="toggle"/>
				</div>

				<div class="option" v-for="(option, index, count) in filterOptions.airlines" :key="option.airlines">
					<div class="main-text">
						<div class="title">{{ option.name }}</div>

						<div class="sub">
							<span>from £{{option.fromPrice}}</span>
						</div>
					</div>
					<Toggle v-model="searchFilters.airlines" :ref="'toggle_'+count" :index="index" class="toggle" @change="handleSelectOption(index)"/>
				</div>
			</div>

		</div>
	
		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Flights Filters' }">
				<button class="button">Continue</button>
			</router-link>
		</div>

	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import Toggle from '@/components/Toggle.vue';
import router from '@/router';

export default {
    data () {
        return {
		   values: {},

		   selectAllAirlines: false,
        }
	},
	components: {
		Toggle,
	},
   computed: {
		...mapState({
			searchResults: state => state.flightsSearch.searchResults,
			searchFilters: state => state.flightsSearch.searchFilters,
		}),
		...mapGetters({
			filterOptions: 'flightsSearch/getFilterOptions',
		})
	},
	watch: {
        selectAllAirlines() {
            for (const key in this.filterOptions.airlines) {
                this.$set(this.searchFilters.airlines, key, this.selectAllAirlines);
            }
        },
    },
    methods: {
		handleSelectOption(index) {
			let newValue = this.searchFilters.airlines[index];
			if(!newValue) {
				// If new value is false, turn off the select all toggle
				this.selectAllAirlines = false;
			}
		}
	},
	mounted() {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Flights Results',
			})
		}
		// Check if all airlines are selected
		this.selectAllAirlines = true;
		for (const key in this.filterOptions.airlines) {
			if (this.searchFilters.airlines.hasOwnProperty(key) && !this.searchFilters.airlines[key]) {
				this.selectAllAirlines = false;
			}
		}

		// Hacky fix: Manually select and deselect the first airline toggle, to ensure Vue is reactive (bug, not sure how to properly solve). Without this, the select all button doesn't visually work until any other toggle is used, then it works. So we just force this here.
		this.$refs.toggle_0[0].click();
		this.$refs.toggle_0[0].click();

	},
	
};
</script>


<style scoped>
	.heading-section {
		text-align: left;
	}
	.options {
		margin-top: 20px;
	}
	.option {
		margin-bottom: 15px;
		position: relative;
	}
	.option.select-all {
		margin-bottom: 28px;
	}
	.option .toggle {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	.toggle {
		float: right;
	}
</style>